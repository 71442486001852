import { createRouter, createWebHistory } from "vue-router";
import { auth } from "@/firebase";
import { useUserStore } from "@/store/userStore";
import { onAuthStateChanged } from "firebase/auth";
import pinia from "@/store/store.js";
import { useProductStore } from "@/store/productStore";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../views/HomeView.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/o-nama",
      name: "about",
      component: () => import("../views/AboutView.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("../views/FAQ.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/LoginView.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/admin-dashboard",
      name: "admin-dashboard",
      component: () => import("../views/AdminDashboard.vue"),
      meta: {
        requiresAdmin: true,
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        const user = auth.currentUser;
        if (!user) {
          next({ name: 'login' });
        } else {
          const userStore = useUserStore();
          if (userStore.isAdmin) {
            next();
          } else {
            next({ name: 'home' });
          }
        }
      },
    },
    {
      path: "/kviz",
      name: "quiz",
      component: () => import("../views/QuizView.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/registracija",
      name: "register",
      component: () => import("../views/RegisterView.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/nalog",
      name: "account",
      component: () => import("../views/AccountView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/zaboravljena-lozinka",
      name: "forgot-password",
      component: () => import("../views/ForgotPasswordView.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/kupovina",
      name: "shop",
      component: () => import("../views/ShopView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/politika-i-placanje",
      name: "policy",
      component: () => import("../views/PaymentPolicy.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/placanje",
      name: "payment",
      component: () => import("../views/PaymentView.vue"),
      children: [
        // Route for the modal (child route)
        {
          path: 'modal',
          name: 'modal',
          component: () => import("../components/ModalComponent.vue"),
          meta: {
            showModal: true
          }
        },
      ],
      beforeEnter: () => {
        const productStore = useProductStore(pinia); // store called here because of the bug on successUrl
      },
      meta: {
        requiresAuth: false,
        showModal: false
      },
    },
  ],
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  let currentUser = await new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      userStore.setUserLogIn(user);
      userStore.setUserId(user?.uid);
      if (!user?.uid) {
        localStorage.removeItem("userId");
      }
      resolve(user);
    });
  });
  if (to.meta.requiresAuth && !currentUser) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    next({ name: 'login', query: { gift: to.query.gift } })
  } else {
    next()
  }
})
export default router;
