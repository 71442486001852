import { defineStore } from "pinia";
import { db } from "@/firebase";
import { doc, setDoc } from "firebase/firestore";
import { getUserId } from "@/service/firebaseAuth";
import { useUserStore } from "@/store/userStore";

export const useAnswersStore = defineStore("answers", {
  state: () => ({
    answers: {},
  }),
  actions: {
    setAnswers(newAnswers) {
      this.answers = newAnswers;
    },
    async saveQuizAnswers() {
      const userStore = useUserStore()
      if (userStore.isUserLoggedIn) {
        const userId = getUserId();
        const userRef = doc(db, "users", userId);
        const quizRef = doc(db, "quizResponses", userId);
        await setDoc(quizRef, {
          ...this.getLastAnswer,
          customer: userRef,
        });
      }
    },
  },
  getters: {
    getLastAnswer() {
      return this.answers;
    },
  },
});
