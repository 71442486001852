import { defineStore } from 'pinia'
import { collection, getDocs } from "firebase/firestore";
import { db, auth } from "@/firebase";

export const useUserStore = defineStore('user', {
    state: () => ({
        isUserLoggedIn: false,
        userId: '',
        isAdmin: false,
        adminData: []
    }),
    actions: {
        setUserLogIn(user) {
            this.isUserLoggedIn = !!user
        },
        setUserId(uid) {
            this.userId = uid
        },
        async fetchAdminStatus() {
            try {
                const querySnapshot = await getDocs(collection(db, "admins"));
                const userEmail = await auth.currentUser?.email;
                querySnapshot.forEach((doc) => {
                    const documentData = doc.data();
                    if (!this.adminData.some((item) => item.email === documentData.email)) {
                        this.adminData.push(documentData)
                    }
                });
                this.adminData.some((user) => {
                    if (user.email === userEmail) {
                        this.isAdmin = user.isAdmin;
                        return true;
                    } else {
                        this.isAdmin = false;
                    }
                })

            } catch (error) {
                this.isAdmin = false;
                console.error('Error fetching admin status:', error);
            }
        },
    },
    getters: {
    },
});