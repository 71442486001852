<template>
  <div class="wrapper">
    <Navigation v-if="!displayNavigation" />
  </div>
  <RouterView />
  <Footer v-if="!displayFooter"></Footer>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navigation,
    Footer,
  },
  computed: {
    displayNavigation() {
      return (
        this.$route.name === "login" ||
        this.$route.name === "register" ||
        this.$route.name === "admin-login"
      );
    },
    displayFooter() {
      return this.$route.name === "admin-dashboard";
    },
  },
};
</script>

<style scoped>

</style>
