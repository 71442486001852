import { signOut, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '@/firebase'
import { useUserStore } from "@/store/userStore";

export const signIn = async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            localStorage.setItem("userId", JSON.stringify(auth.currentUser.uid));
        })
}

export const signOutFromAccount = async () => {
    return await signOut(auth)
        .then(() => {
            localStorage.removeItem("userId");
            localStorage.removeItem("product");
        })
        .catch((error) => { });
}

export const isUserLoggedIn = () => {
    const userStore = useUserStore();
    return userStore.isUserLoggedIn;
}

export const getUserId = () => {
    const userStore = useUserStore();
    return userStore?.userId || JSON.parse(localStorage.getItem("userId"));
}
