import deepmerge from 'deepmerge'


const base = {
  baseUrl: 'http://localhost:5173',
  allSecure: {
    url: 'https://asxgw.paymentsandbox.cloud',
    publicIntegrationKey: 'Mqoa5JCKADR9bpDBmDqr'
  },
  cloudFunctions: {
    url: 'http://localhost:5001/winesub-staging/europe-west1'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyA0Ixo2AG82VAkeoHo8XoXD_6qFrBbFuZw',
    authDomain: 'winesub-staging.firebaseapp.com',
    projectId: 'winesub-staging',
    storageBucket: 'winesub-staging.appspot.com',
    messagingSenderId: '682784219064',
    appId: '1:682784219064:web:05b028af36b1f23232794d'
  }
}

const staging = {
  baseUrl: 'https://winesub-earc.onrender.com',
  cloudFunctions: {
    url: 'https://europe-west1-winesub-staging.cloudfunctions.net'
  }
}

const prod = {
  baseUrl: 'https://winesub.rs',
  allSecure: {
    url: 'https://asxgw.com',
    publicIntegrationKey: 'ER7VFKJMNQ0P7qMSCkfz'
  },
  cloudFunctions: {
    url: 'https://europe-west1-winesub-9f2c5.cloudfunctions.net'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBBg-UZMaRNPERaBMHY1HQi1xD6NKVY128',
    authDomain: 'winesub-9f2c5.firebaseapp.com',
    projectId: 'winesub-9f2c5',
    storageBucket: 'winesub-9f2c5.appspot.com',
    messagingSenderId: '406095304510',
    appId: '1:406095304510:web:85edc31eed168f6712be6f',
    measurementId: 'G-RYDKBBHLYV'
  }
}

const env = import.meta.env.MODE

let envSpecifics = {}
if (env === 'production') envSpecifics = prod
else if (env === 'staging') envSpecifics = staging

export default deepmerge.all([
  base,
  envSpecifics
])
