import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { getAuth, browserSessionPersistence } from "firebase/auth";
import config from '../config'

const firebaseConfig = config.firebaseConfig

const firebaseInit = initializeApp(firebaseConfig);

const auth = getAuth(firebaseInit);
const db = getFirestore(firebaseInit);

auth.setPersistence(browserSessionPersistence);

const getUserDocById = async (id) => {
  const querySnapshot = await getDocs(collection(db, "users"));
  const userDoc = querySnapshot.docs.find(doc => doc.data().userId === id);
  if (userDoc) {
    return userDoc;
  } else {
    return null; // Return null or undefined (or any default value) if user is not found
  }
}

export {
  auth,
  db,
  getUserDocById,
  firebaseInit
}
