<template>
  <div class="footer-wrapper">
    <div class="footer">
      <div class="footer-left">
        <img src="@/assets/logo-no-bottle.png" alt="" />
        <RouterLink class="routerLink" to="/faq">
          <p>FAQ</p>
        </RouterLink>
        <RouterLink class="routerLink" to="/politika-i-placanje">
          <p>Uslovi plaćanja</p>
        </RouterLink>
      </div>
      <div class="footer-center">
        <h2>Kontakt info</h2>
        <p>ENOTRIA DOO</p>
        <p>Matični broj: 20102101</p>
        <p>PIB: 104145029</p>
        <p>Adresa: Vladimira Popovića 26/28,<br />Beograd, Srbija</p>
        <p>Broj telefona: +381 69 1804 577</p>
        <p>Email: info@winesub.rs</p>
        <p>Radno vreme: 09 - 17h</p>
        <p>
          Zapratite nas
          <v-btn
            icon="mdi-instagram"
            size="large"
            color="secondary"
            variant="text"
            @click="openInNewTab('https://www.instagram.com/winesub.rs/')"
          ></v-btn>
          <v-btn
            icon="mdi-facebook"
            size="large"
            color="secondary"
            variant="text"
            @click="openInNewTab('https://www.facebook.com/winehouse11')"
          ></v-btn>
        </p>
        <p>© WineSub 2023. All Rights Reserved.</p>
      </div>
      <div class="footer-right">
        <h2>Secured By</h2>
        <div class="payment-images">
          <div
            class="clickable"
            @click="openInNewTab('https://www.bancaintesa.rs/')"
          >
            <img src="@/assets/banca-intesa.png" alt="bankaintesa" />
          </div>
          <div
            class="clickable"
            @click="openInNewTab('https://www.allsecure.rs/')"
          >
            <img src="@/assets/allsecure.svg" alt="allsecure" height="40" />
          </div>
          <div
            class="clickable"
            @click="
              openInNewTab(
                'https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html'
              )
            "
          >
            <img
              src="https://www.allsecure.rs/wp-content/uploads/2021/01/logo-payments-visasecure.svg"
              alt="visasecure"
            />
          </div>
          <div
            class="clickable"
            @click="
              openInNewTab(
                'http://www.mastercard.com/rs/consumer/credit-cards.html'
              )
            "
          >
            <img
              src="https://www.allsecure.rs/wp-content/uploads/2021/01/logo-payments-mcidcheck.svg"
              alt="mcidcheck"
            />
          </div>
        </div>
        <h2>Metodi Plaćanja</h2>
        <div class="payment-images">
          <img
            src="https://www.allsecure.rs/wp-content/uploads/2022/04/logo-payments-visa.svg"
            alt="VISA"
          />
          <img
            src="https://www.allsecure.rs/wp-content/uploads/2021/01/logo-payments-master-1.svg"
            alt="mastercard"
          />
          <img
            src="https://www.allsecure.rs/wp-content/uploads/2021/01/logo-payments-maestro-1.svg"
            alt="maestro"
          />
          <img
            src="https://www.allsecure.rs/wp-content/uploads/2022/04/logo-payments-dinacard.svg"
            alt="dinacard"
          />
          <img
            src="https://www.allsecure.rs/wp-content/uploads/2021/01/logo-payments-amex-1.svg"
            alt="american express"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    openInNewTab(url) {
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-around;
  padding: 48px 0;
  a {
    text-decoration: none;
    color: #0d0d0d;
    cursor: pointer;
    outline: unset;
    border: unset;
    &:hover {
      text-decoration: underline;
    }
  }
  &-wrapper {
    background: #f7f7f7;
  }
  &-left {
    padding-top: 16px;
    img {
      height: 100px;
      margin-left: 16px;
    }
  }
  &-center {
    padding: 16px 0;
    h2 {
      text-align: center;
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 16px;
    .payment-images {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
.clickable {
  cursor: pointer;
  height: 50px;
}
p {
  padding: 6px 0;
  text-align: center;
}
h2 {
  margin-bottom: 12px;
}
img {
  height: 50px;
}
@media only screen and (max-width: 950px) {
  .footer {
    flex-direction: column;
    &-wrapper {
      background: #f7f7f7;
    }
    &-left {
      gap: 12px;
      img {
        display: block;
        margin: 0 auto;
        width: 90%;
        max-width: 250px;
      }
    }
    &-right {
      padding: 0 20px;
    }
  }
  .footer-center {
    p {
      padding: 15px 0;
    }
    img {
      display: block;
      margin: 0 auto;
    }
  }

  p {
    width: 90%;
    margin: 0 auto;
  }
}
</style>
