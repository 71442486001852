<template>
  <nav>
    <div class="logoMenu">
      <RouterLink class="routerLink" to="/">
        <img src="@/assets/logo-no-bottle.png" alt="" />
      </RouterLink>
      <div class="hamburgerMenu" @click="handleHamburgerClick">
        <span></span>
      </div>
    </div>
    <div :class="navigationMenuClass">
      <RouterLink class="routerLink" to="/" @click.native="handleHamburgerClick"
        >Početna</RouterLink
      >
      <RouterLink
        class="routerLink"
        to="/o-nama"
        @click.native="handleHamburgerClick"
        >O nama</RouterLink
      >
      <RouterLink
        class="routerLink"
        to="/kupovina"
        @click.native="handleHamburgerClick"
        >Shop</RouterLink
      >
      <RouterLink
        class="routerLink"
        to=""
        @click.native="handleGiftClick"
        >Poklon paket</RouterLink
        >
      <RouterLink
        class="routerLink"
        to="/faq"
        @click.native="handleHamburgerClick"
        >FAQ</RouterLink
        >
      <RouterLink class="routerLink" to="/kviz">
        <v-btn :elevation="0" color="primary" @click="handleHamburgerClick"
          >Započni kviz</v-btn
        >
      </RouterLink>

      <RouterLink class="routerLink" v-if="!isLoggedIn" to="/login">
        <v-btn
          class="loginBtn"
          :elevation="0"
          color="primary"
          variant="outlined"
          @click="handleHamburgerClick"
          >Prijavi se</v-btn
        >
      </RouterLink>
      <div v-else class="navigation-elements-logged-in">
        <v-menu
          :open-delay="50"
          :close-delay="100"
          :open-on-click="true"
          location="bottom end"
        >
          <template v-slot:activator="{ props }">
            <v-avatar v-bind="props">
              <v-icon icon="mdi-account"></v-icon>
            </v-avatar>
            <v-avatar v-bind="props">
              <v-icon icon="mdi-chevron-down"></v-icon>
            </v-avatar>
          </template>
          <v-list>
            <v-list-item>
              <RouterLink class="routerLink" to="/nalog">
                <v-btn color="black" variant="text" :elevation="0">
                  Moj nalog
                </v-btn>
              </RouterLink>
            </v-list-item>
            <v-list-item v-if="this.userStore.isAdmin">
              <RouterLink class="routerLink" to="/admin-dashboard">
                <v-btn color="black" variant="text" :elevation="0">
                  Admin panel
                </v-btn>
              </RouterLink>
            </v-list-item>
            <v-list-item>
              <RouterLink class="routerLink" to="/">
                <v-btn
                  @click="logout"
                  color="black"
                  variant="text"
                  :elevation="0"
                  >Odjavi se</v-btn
                >
              </RouterLink>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </nav>
</template>

<script>
import { useUserStore } from "@/store/userStore";
import { useAnswersStore } from "@/store/answersStore";
import { signOutFromAccount, isUserLoggedIn } from "@/service/firebaseAuth";
import { mapStores } from "pinia";

export default {
  name: "Navigation",
  data() {
    return {
      userStore: useUserStore(),
      answerStore: useAnswersStore(),
      isMenuOpen: false,
    };
  },
  methods: {
    logout() {
      signOutFromAccount();
    },
    handleHamburgerClick() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleGiftClick() {
      this.handleHamburgerClick();
      this.$router.push({ name: "shop", query: { gift: true } });
    },
    getUserImage() {
      return new URL(`../assets/user.png`, import.meta.url);
    },
  },
  computed: {
    ...mapStores(useAnswersStore),
    navigationMenuClass() {
      return this.isMenuOpen
        ? "navigation-elements-open"
        : "navigation-elements";
    },
    isLoggedIn() {
      return isUserLoggedIn();
    },
  },
  created() {
    this.userStore.fetchAdminStatus();
  },
};
</script>

<style lang="scss" scoped>
$avatar-height: 0;

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  height: 80px;
  background: #f7f7f7;
  box-shadow: 0px 0px 6px #dedede;
}
nav a {
  text-decoration: none;
  color: #0d0d0d;
  cursor: pointer;
  outline: unset;
  border: unset;
}
.navigation-elements,
.navigation-elements-open {
  display: flex;
  align-items: center;
  gap: 48px;
  &-logged-in {
    display: flex;
    align-items: center;
  }
}

:deep(.v-btn) {
  width: 100%;
}

.logoMenu {
  display: flex;
  align-items: center;
  img {
    height: 80px;
  }
  .hamburgerMenu {
    display: none;
    cursor: pointer;
    user-select: none;
    span {
      position: relative;
      &,
      &:before,
      &:after {
        display: block;
        width: 35px;
        height: 4px;
        background-color: #414141;
        border-radius: 5px;
      }
      &:after {
        width: 45px;
        right: 0;
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
      }
      &:before {
        bottom: -10px;
      }
      &:after {
        bottom: -20px;
      }
    }
  }
}
.navigation-elements-logged-in {
  cursor: pointer;
}

@media only screen and (max-width: 1170px) {
  nav {
    flex-direction: column;
    height: initial;
  }
  .navigation-elements,
  .navigation-elements-open {
    transition: opacity 0.7s ease-out, padding-top 0.7s ease-out,
      max-height 0.7s ease-out;
    max-height: 0;
    opacity: 0;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    background-color: #f7f7f7;
  }
  .navigation-elements-open {
    padding: 20px 0;
    max-height: 400px;
    opacity: 1;
    transition: opacity 0.7s ease-out, max-height 0.7s ease-out;
  }

  :deep(.v-btn) {
    width: 90vw;
  }
  .logoMenu {
    justify-content: space-between;
    width: 100%;

    .hamburgerMenu {
      padding-bottom: 20px;
      display: inline-block;
      margin-right: 10px;
    }

    img {
      max-width: 246px;
      margin-left: 10px;
      transform: scale(0.9, 0.9);
    }
  }
  .loginBtn {
    margin: -20px 0 20px 0;
  }
}
</style>
