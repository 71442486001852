import { defineStore } from 'pinia'

export const useProductStore = defineStore('product', {
    state: () => ({
        allProducts: [],
        product: {}
    }),
    actions: {
        addNewProduct(product) {
            this.allProducts.push(product);
        },
        selectedProduct(product) {
            this.product = product
        }
    },
    getters: {
        getProductById: (state) => (productId) => {
            return state.allProducts.find((product) => product.id === productId);
        },
        getProduct: (state) => state.product
    },
});